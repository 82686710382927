
import React, { useRef, useState } from "react";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import { useTheme } from "@emotion/react";
import image_saz from "../assets/image_saz.png";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import {IconButton,InputAdornment }from '@mui/material';
import {Visibility , VisibilityOff} from '@mui/icons-material';
 
const label = { inputProps: { "aria-label": "Checkbox demo" } };
 
const Login = () => {
  const [isSignInForm, setIsSignInForm] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const name = useRef(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const [token, setToken] = useState("");
 
 
  const toggleSignInForm = () => {
    setIsSignInForm(!isSignInForm);
  };

    const [showPassword, setShowPassword] = useState(false);
  
    const togglePasswordVisibility = () => {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    };
 
  const [formData, updateFormData] = React.useState();

  // const [userData, setUserData] = useState({
  //   email:"",
  //   password:"",
  //   token:"",
  // })
 
  const isAlpha = (input) => /^[a-zA-Z ]+$/.test(input);
  const handleChange = (e) => {
    const { name, value } = e.target;
    let trimmedValue = value.trim();
 
    if (name === 'name' && !isAlpha(trimmedValue)) {
      setErrorMessage("Full name should not contain numbers and/or special characters.");
    } else {
      setErrorMessage(null);
    }
 
    updateFormData({
      ...formData,
      [name]: trimmedValue,
    });
  };
    // const handleChange = (e) => {
    //   updateFormData({
    //     ...formData,
 
    //     // Trimming any whitespace
    //     [e.target.name]: e.target.value.trim(),
    //     // [e.target.password]: e.target.value.trim()
    //   });
    // };
 
//     const handleSubmit1=async()=>
// {
//   console.log("name,email,password,confirmpassword",formData.name,formData.email,formData.password,formData.confirmPassword)
//   const email=formData.email;
//   const password=formData.password
//   const confirmPassword=formData.confirmPassword;
 
//   const name=formData.name;
 
//   let result=await fetch("https://masatv.net/backendserver/api/userinfos/post",{
//     method:"POST",
//     body:JSON.stringify({name,email,password,confirmPassword}),
 
//     headers:
//     {
//       "Content-Type":"application/json"
//     }
//   });
//   result=await result.json();
//   console.log(result);
//   if(result)
//   {
//     localStorage.setItem("user",JSON.stringify(result))
//     navigate("/dashboard");
//   }
 
 
// }
const handleSubmit = async () => {
  const { email, password, name, confirmPassword } = formData;
 
  if (!isSignInForm) {
    // Sign Up Logic
    let result = await fetch("https://masatv.net/backendserver/api/user/signup", {
      method: "POST",
      body: JSON.stringify({ email, password, name, confirmPassword }),
      headers: {
        "Content-Type": "application/json",
      },
    });
 
    result = await result.json();
    console.log(result);
 
    if (result.status === 'success') {
      let data = { id:'1234', name:'Suresh'}
      localStorage.setItem("user", JSON.stringify(data));
      navigate("/dashboard");
    } else {
      // Handle signup failure, show an error message, etc.
      setErrorMessage(result.message || "Signup failed. Please try again.");    }
  } else {
    // Sign In Logic
    let result = await fetch("https://masatv.net/backendserver/api/user/login", {
      method: "POST",
      body: JSON.stringify({ email, password }),
 
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
    });
 
    result = await result.json();
    console.log(result,"sarigama");
 
    if (result.status === 'success') {
      
      setToken(result.token);
      localStorage.setItem("token", JSON.stringify(result.token));
      console.log(result.body,"abc");
      // let data = { id:'1234', name:'Suresh'}
      // localStorage.setItem("user", JSON.stringify(data));
      navigate("/dashboard");
    } else {
      // Handle login failure, show an error message, etc.
      setErrorMessage(result.message || "Login failed. Please check your credentials.");
    }
  }
};
 
const handleKeyPress = (event) => {
  // look for the `Enter` keyCode
  if (event.keyCode === 13 || event.which === 13) {
    handleSubmit()
  }
}
 
// const[user,setUser]=useState(null);
// const[email,setEmail]=useState("")
// const[password,setPassword]=useState("")
 
  return (
    <Container component="main" maxWidth="sm" sx={{bgcolor:"black" , borderRadius:"0.55rem" }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // borderRadius:"0.55rem"
        }}
      >
        <img style={{marginLeft:"0px",marginBottom:"-40px",marginTop:"-20px",height:"160px" , width:"160px"}}src="/assets/Masa_Logo.png" alt= "no image"></img>
 
        <Typography component="h1" variant="h5" mt={"50px"} >
          {isSignInForm ? "Sign In" : "Sign Up"}
        </Typography>
        {!isSignInForm && (
          <TextField
            required
            fullWidth
            margin="normal"
            label="Full Name"
            type="text"
            name="name"
            onChange={handleChange}
            onKeyPress={handleKeyPress}
          />
        )}
        <TextField
          required
          fullWidth
          margin="normal"
          label="Email"
          type="email"
          name="email"
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
        <TextField
          required
          fullWidth
          margin="normal"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          name="password"
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility} edge="end" style={{
                transition: 'opacity 0.3s ease', // Adding transition for opacity
                opacity: 1, // Initial opacity
                '&:hover': { // Adding hover effect
                  opacity: 0.7,
                },
              }}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {!isSignInForm && (
          <Box sx={{ textAlign: "left", marginY: 1 }}>
            <Checkbox {...label} />
            <Typography variant="caption" gutterBottom>
              Accept Terms and conditions
            </Typography>
          </Box>
        )}
         {errorMessage && (
          <Alert severity="error" sx={{ width: "100%", marginBottom: 2 }}>
            {errorMessage}
          </Alert>
        )}
        <Button
        className="loginbutton"
          variant="contained"
          onClick={handleSubmit}
          fullWidth
          sx={{ marginTop: 2, bgcolor:"#bda140"
          ,"&:hover": {
            bgcolor: "black",
            color:"#bda140",
            border: "2px solid #bda140",
          },}}
        >
          {isSignInForm ? "Sign In" : "Sign Up"}
        </Button>
         <Typography
          variant="body2"
          onClick={toggleSignInForm}
          color="primary"
          sx={{ textAlign: "center", cursor: "pointer", marginTop: 2,marginBottom:"20px" }}
        >
         {/* {isSignInForm
            ? "Don't have an account? Sign Up now!"
            : "Already registered? Sign In now!"}    */}
        </Typography> 
      </Box>
    </Container>
  );
};
 
 
export default Login;